import api from '../../../utils/api';
import { API_KYC } from '../../../utils/v4/api-url';
import { notificationDanger } from '@/utils/notification';

const namespaced = true;
const state = {

};

const mutations = {

};

const actions = {
    async getListBank(context, data){
        try {
            return await api.apiGetAuth(API_KYC.GET_LIST, data);
        } catch (error) {
            notificationDanger(error);
        }
    },
    async uploadFile(context, payload) {
        try {
            const response = await api.apiPostAuth(API_KYC.UPLOAD_FILE, payload, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return response.data;
        } catch (error) {
            notificationDanger(error);
        }
    },
};

const getters = {

};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters
};
