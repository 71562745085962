<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable">
        <template v-if="internetConnection">
            <LoaderPage v-if="isLoading"/>
            <template v-else>
              <div>
                <div class="uk-width-1-4@m uk-text-lead">
                  KYC Bank Matching
                </div>
              </div>
              <div uk-grid>
                <div class="uk-width-1-4@m">
                  <button
                    type="button"
                    class="uk-button uk-button-default uk-width-1-1 myrobin-primary-button-filled uk-flex uk-flex-center uk-flex-middle"
                    @click="showDownloadTemplateModalBox"
                  >
                    <img :src="`${images}/download-outline-white.svg`" alt="Download icon" loading="lazy" />
                    <span class="uk-margin-small-left">Download template</span>
                  </button>
                </div>
                <div class="uk-width-1-4@m">
                  <button
                    type="button"
                    class="uk-button uk-button-default uk-width-1-1 myrobin-primary-button-outlined uk-flex uk-flex-center uk-flex-middle"
                    @click="showUploadTemplateModalBox"
                  >
                    <img :src="`${images}/upload-outline-primary.svg`" alt="Upload icon" loading="lazy" />
                    <span class="uk-margin-small-left">Upload template</span>
                    <input
                      name="excelFile"
                      type="file"
                      ref="excelFile"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      style="display: none;"
                      @change="previewFiles({ event: $event })"
                    >
                  </button>
                </div>
                <div class="uk-flex uk-flex-center uk-flex-middle">
                  <div class="uk-width-expand">
                  </div>
                </div>
              </div>
            </template>
        </template>

        <NoInternetConnection v-else />
      <div id="formUploadModal" uk-modal esc-close="false">
        <div class="uk-modal-dialog">
          <div class="uk-modal-header">
            <h2 class="uk-modal-title">Upload File</h2>
          </div>
          <div class="uk-modal-body">
            <p>Are you sure to upload this file?</p>
            <p>File Name: <b>{{ fileAttachment.file.name || 'name file' }}</b></p>
          </div>
          <div class="uk-modal-footer uk-text-right">
            <button v-if="!buttonLoading" class="uk-button uk-button-primary" type="button" @click="SubmitUpload">Upload</button>
            <LoaderButton v-else/>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import { getUserLogin } from '@/utils/auth';
// import formatter from '@/utils/formatter';
import { excelDownloaderWithMultipleSheet } from '@/utils/helper';

export default {
    name: 'VacancyReference',
    components: {
        LoaderButton: () => import('@/components/globals/LoaderButton'),
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        NoInternetConnection: () => import('@/components/globals/NoInternetConnection'),
    },
    data(){
        return {
            images: PREFIX_IMAGE,
            user_cred: getUserLogin(),
            internetConnection: true,
            isLoading: false,
            tableLoading: false,
            buttonLoading: false,
            listBank: [],
            fileAttachment: {
                url: '',
                file: ''
            },
        };
    },
    async mounted(){
        try {
            if (!navigator.onLine) return this.internetConnection = false;
            this.isLoading=true;
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.create-or-edit-vacancy-modal-box')) window.UIkit.modal('.create-or-edit-vacancy-modal-box').$destroy(true);
        if (window.UIkit.modal('.confirmation-delete-vacancy-modal-box')) window.UIkit.modal('.confirmation-delete-vacancy-modal-box').$destroy(true);
    },
    computed: {
    },
    watch: {
    },
    methods: {
        ...mapActions({
            getListBank: 'kycV4/getListBank',
            uploadFile: 'kycV4/uploadFile',
        }),
        SubmitUpload() {
            this.buttonLoading = true;

            let formData = new FormData();
            formData.append('excel', this.fileAttachment.file);

            this.uploadFile(formData)
                .then((response) => {
                    if (response.status === 'OK') {
                        this.buttonLoading = false;
                        notificationSuccess('File Successfully Uploaded');
                        return window.UIkit.modal('#formUploadModal').hide();
                    }
                })
                .catch(() => {
                    this.buttonLoading = false;
                    return window.UIkit.modal('#formUploadModal').hide();
                });
        },
        async previewFiles({ event }) {
            if (event.target.files && event.target.files.length > 0) {
                this.fileAttachment.url = URL.createObjectURL(event.target.files[0]);
                this.fileAttachment.file = event.target.files[0];
                await window.UIkit.modal('#formUploadModal').show();
            }
        },
        async showDownloadTemplateModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    const { data } = await this.getListBank();
                    const excelMap1 = {
                        sheetName: 'Format',
                        dataList: [{
                            "No": '',
                            "Nama Pemilik Rekening": '',
                            "Nama Bank": '',
                            "Kode Bank": '',
                            "Nomor Rekening Bank": '',
                            "NIK": '',
                            "Similarity": '',
                        }]
                    };

                    const excelMap2 = {
                        sheetName: 'Bank Code',
                        dataList: data.docs.map((item, index) => {
                            return {
                                "No": index + 1,
                                "Bank Name": item.name,
                                "Bank Code": item.code,
                                "Swift Code": item.swift_code,
                            };
                        })
                    };

                    excelDownloaderWithMultipleSheet([excelMap1, excelMap2], 'Xendit Integration.xlsx');
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showUploadTemplateModalBox() {
            try {
                if (navigator.onLine) {
                    this.internetConnection = true;
                    if (this.buttonLoading) {
                        await window.UIkit.modal('#formUploadModal').show();
                    } else {
                        this.$refs.excelFile.click();
                    }
                } else {
                    this.internetConnection = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        hideDropdown() {
            const closeDropdown = window.UIkit.dropdown('.uk-dropdown', {
                delayHide: 0
            });
            closeDropdown ? closeDropdown.hide() : null;
        },
    }
};
</script>
<style scoped>
.myrobin-primary-button-filled {
  background: #0ABAB5;
  color: #FFFFFF;
}

.myrobin-primary-button-outlined {
  border: 1px solid #0ABAB5;
  color: #0ABAB5;
}

</style>
