<template>
    <div id="toggle-animation" class="width-large, card-sidebar">
        <div class="sidebar uk-card uk-card-default uk-card-body uk-height-1-1">
            <img :src="`${images}/logo_full.png`" alt="" class="uk-margin-bottom" width="100">
            <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
                <li class="title">
                    <router-link :to="''"><span class="uk-margin-small-right"></span><strong>DASHBOARD</strong></router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/dashboard'}">
                    <router-link :to="'/admin/dashboard'"><span class="uk-margin-small-right" uk-icon="icon: home"></span> Home</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/dashboard/mitra-candidate'}">
                    <router-link :to="'/admin/dashboard/mitra-candidate'"><span class="uk-margin-small-right" uk-icon="icon: users"></span> Mitra & Candidate</router-link>
                </li>
            </ul>
            <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
                <li class="title" style="border-top: 0">
                    <router-link :to="''"><span class="uk-margin-small-right"></span><strong>USER</strong></router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/membership'}">
                    <router-link :to="'/admin/membership'"><span class="uk-margin-small-right" uk-icon="icon: user"></span> Premium Membership</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/candidate'}">
                    <router-link :to="'/admin/candidate'"><span class="uk-margin-small-right" uk-icon="icon: user"></span> Candidate</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/mitra'}">
                    <router-link :to="'/admin/mitra'"><span class="uk-margin-small-right" uk-icon="icon: user"></span> Mitra</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/user-employer'}">
                    <router-link :to="'/admin/user-employer'"><span class="uk-margin-small-right" uk-icon="icon: user"></span> Employer</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/administrator'}" v-if="consist(user_cred.roles, ['qwe123','superadmin-tech','superadmin-product'])">
                    <router-link :to="'/admin/administrator'"><span class="uk-margin-small-right" uk-icon="icon: user"></span> Administrator</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/admin-job-vacancy'}" v-if="consist(user_cred.roles, ['qwe123','superadmin-tech','superadmin-product','admin-operation'])">
                    <router-link :to="'/admin/admin-job-vacancy'"><span class="uk-margin-small-right" uk-icon="icon: user"></span>
                        Administrator Job Vacancy <br> (WhatsApp)
                    </router-link>
                </li>
            </ul>
            <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
                <li class="title" style="border-top: 0">
                    <router-link :to="''"><span class="uk-margin-small-right"></span><strong>ASSESSMENT TEST</strong></router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/test-category'}">
                    <router-link :to="'/admin/test-category'"><span class="uk-margin-small-right" uk-icon="icon: folder"></span> Test Category</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/test-question'}">
                    <router-link :to="'/admin/test-question'"><span class="uk-margin-small-right" uk-icon="icon: question"></span> Test Question</router-link>
                </li>
            </ul>
            <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
                <li class="title" style="border-top: 0">
                    <router-link :to="''"><span class="uk-margin-small-right"></span><strong>CLIENT</strong></router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/mitra/company'}">
                    <router-link :to="'/admin/mitra/company'"><span class="uk-margin-small-right" uk-icon="icon: users"></span> Company</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/contract'}">
                    <router-link :to="'/admin/contract'"><span class="uk-margin-small-right" uk-icon="icon: file-pdf"></span> Contract</router-link>
                </li>
            </ul>
            <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
                <li class="title" style="border-top: 0">
                    <router-link :to="''"><span class="uk-margin-small-right"></span><strong>KORMO</strong></router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/kormo/seekers'}">
                    <router-link :to="'/admin/kormo/seekers'"><span class="uk-margin-small-right" uk-icon="icon: users"></span> Seekers</router-link>
                </li>
            </ul>
            <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
                <li class="title" style="border-top: 0">
                    <router-link :to="''"><span class="uk-margin-small-right"></span><strong>MARKETING</strong></router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/marketing/banner'}">
                    <router-link :to="'/admin/marketing/banner'"><span class="uk-margin-small-right" uk-icon="icon: image"></span> Banner</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/marketing/testimonial'}">
                    <router-link :to="'/admin/marketing/testimonial'"><span class="uk-margin-small-right" uk-icon="icon: star"></span> Testimonial</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/marketing/social_media'}">
                    <router-link :to="'/admin/marketing/social_media'"><span class="uk-margin-small-right" uk-icon="icon: users"></span> Social Media Myrobin</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/marketing/candidates'}">
                    <router-link :to="'/admin/marketing/candidates'"><span class="uk-margin-small-right" uk-icon="icon: users"></span> User and Cadidate Report</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/marketing/jobs'}">
                    <router-link :to="'/admin/marketing/jobs'"><span class="uk-margin-small-right" uk-icon="icon: file-pdf"></span> Jobs Report</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/marketing/posts'}">
                    <router-link :to="'/admin/marketing/posts'"><span class="uk-margin-small-right" uk-icon="icon: file-edit"></span> Posts Report</router-link>
                </li>
                <li
                    v-if="consist(user_cred.roles, ['qwe123', 'user-marketing', 'admin-operation', 'admin-bd', 'superadmin-tech', 'superadmin-product', 'user-management'])"
                    :class="{'uk-active': this.$route.fullPath === '/admin/marketing/silver_package'}"
                >
                    <router-link :to="'/admin/marketing/silver_package'"><span class="uk-margin-small-right" uk-icon="icon: bag"></span> Silver Package</router-link>
                </li>
            </ul>
            <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
                <li class="title" style="border-top: 0">
                    <router-link :to="''"><span class="uk-margin-small-right"></span><strong>COMMUNITY</strong></router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/community/community'}">
                    <router-link :to="'/admin/community/community'"><span class="uk-margin-small-right" uk-icon="icon: users"></span> Komunitas</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/community/live-content'}">
                    <router-link :to="'/admin/community/live-content'">
                        <img v-if="this.$route.name === 'LiveContent'" :src="`${images}/camera-reels-yellow.svg`" alt="Camera reels icon">
                        <img v-else :src="`${images}/camera-reels-grey.svg`" alt="Camera reels icon">
                        <span class="uk-margin-small-left">Live Content</span>
                    </router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/community/coupon'}">
                    <router-link :to="'/admin/community/coupon'">
                        <svg style="margin-right: 10px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :d="path" :fill="this.$route.fullPath === '/admin/community/coupon' ? '#f5b338' : '#999'"/>
                        </svg>
                        Voucher
                    </router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath.search('community/mywarung')>=0}">
                    <router-link :to="'/admin/community/mywarung'">
                        <svg style="margin-right: 10px;" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path :d="icon_warung" :fill="this.$route.fullPath.search('community/mywarung')>=0 ? '#f5b338' : '#999'"/>
                        </svg>
                        MyWarung
                    </router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath.search('community/ewa')>=0}">
                    <router-link :to="'/admin/community/ewa'">
                        <img v-if="this.$route.name === 'EWA'" :src="`${images}/ic_ewa-yellow.svg`" alt="Ewa icon">
                        <img v-else :src="`${images}/ic_ewa-grey.svg`" alt="Ewa icon">
                        <span class="uk-margin-small-left">Early Wage Access</span>
                    </router-link>
                </li>
            </ul>
            <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
                <li class="title" style="border-top: 0">
                    <router-link :to="''"><span class="uk-margin-small-right"></span><strong>REPORTING</strong></router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/report/user'}">
                    <router-link :to="'/admin/report/user'"><span class="uk-margin-small-right" uk-icon="icon: users"></span> User Report</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/report/mitra'}">
                    <router-link :to="'/admin/report/mitra'"><span class="uk-margin-small-right" uk-icon="icon: users"></span> Mitra Report</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/report/company'}">
                    <router-link :to="'/admin/report/company'"><span class="uk-margin-small-right" uk-icon="icon: file-text"></span> Company Report</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/report/entry'}">
                    <router-link :to="'/admin/report/entry'"><span class="uk-margin-small-right" uk-icon="icon: file-edit"></span> Entry User Report</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/report/company_attendance_tracker'}">
                    <router-link :to="'/admin/report/company_attendance_tracker'"><span class="uk-margin-small-right" uk-icon="icon: calendar"></span> Company Attendance Tracker Report</router-link>
                </li>
            </ul>
            <ul class="uk-nav-default uk-nav-parent-icon" uk-nav>
                <li class="title" style="border-top: 0">
                    <router-link :to="''"><span class="uk-margin-small-right"></span><strong>EXTRA</strong></router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/all-user'}">
                    <router-link :to="'/admin/all-user'"><span class="uk-margin-small-right" uk-icon="icon: users"></span> All User</router-link>
                </li>
                <li :class="{'uk-active': this.$route.name === 'KycValidator'}">
                  <router-link :to="'/admin/kyc-validator'"><span class="uk-margin-small-right" uk-icon="icon: check"></span> KYC Validator</router-link>
                </li>
                <li :class="{'uk-active': this.$route.name === 'JobPreference'}">
                    <router-link :to="'/admin/job-preference'"><span class="uk-margin-small-right" uk-icon="icon: hashtag"></span> Job Preference</router-link>
                </li>
                <li :class="{'uk-active': this.$route.name === 'ApplicantWhatsappTemplate'}">
                    <router-link :to="'/admin/applicant/whatsapp-template'"><span class="uk-margin-small-right" uk-icon="icon: whatsapp"></span> Applicant - WhatsApp Template</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/vacancy-reference'}">
                    <router-link :to="'/admin/vacancy-reference'"><span class="uk-margin-small-right" uk-icon="icon: social"></span> Vacancy Reference</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/job'}">
                    <router-link :to="'/admin/job'"><span class="uk-margin-small-right" uk-icon="icon: file-text"></span> Job</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/job-combine'}">
                    <router-link :to="'/admin/job-combine'"><span class="uk-margin-small-right" uk-icon="icon: file-text"></span> Job Combine</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/leave'}">
                    <router-link :to="'/admin/leave'"><span class="uk-margin-small-right" uk-icon="icon: clock"></span> Leave</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/post'}">
                    <router-link :to="'/admin/post'"><span class="uk-margin-small-right" uk-icon="icon: instagram"></span> Post</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/hospitality/category'}">
                    <router-link :to="'/admin/hospitality/category'"><span class="uk-margin-small-right" uk-icon="icon: folder"></span>Hospitality - Category</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/worker'}">
                    <router-link :to="'/admin/worker'"><span class="uk-margin-small-right" uk-icon="icon: user"></span> PWA Worker</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/employer'}">
                    <router-link :to="'/admin/employer'"><span class="uk-margin-small-right" uk-icon="icon: user"></span> PWA Employer</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/cv-classification'}">
                    <router-link :to="'/admin/cv-classification'"><span class="uk-margin-small-right" uk-icon="icon: search"></span> CV Classified</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/mitra/daily-recap'}">
                    <router-link :to="'/admin/mitra/daily-recap'"><span class="uk-margin-small-right" uk-icon="icon: calendar"></span> Daily Recap</router-link>
                </li>
                <li :class="{'uk-active': this.$route.fullPath === '/admin/mitra/schedule'}">
                    <router-link :to="'/admin/mitra/schedule'"><span class="uk-margin-small-right" uk-icon="icon: calendar"></span> Schedule</router-link>
                </li>
                <li :class="{'uk-active': this.$route.name === 'PlatformVersion'}">
                    <router-link :to="'/admin/platform-version'">
                        <img v-if="this.$route.name === 'PlatformVersion'" :src="`${images}/binary-yellow.svg`" alt="Binary icon">
                        <img v-else :src="`${images}/binary-grey.svg`" alt="Binary icon">
                        <span>Platform Version</span>
                    </router-link>
                </li>
                <li
                    v-if="consist(user_cred.roles, ['qwe123','superadmin-tech', 'superadmin-product', 'admin-operation'])"
                    :class="{'uk-active': this.$route.name === 'AdditionalDocument'}"
                >
                    <router-link :to="'/admin/additional-document'">
                        <img v-if="this.$route.name === 'AdditionalDocument'" :src="`${images}/additional-do-icon-yellow.svg`" alt="additional document"> 
                        <img v-else :src="`${images}/additional-do-icon.svg`" alt="additional document">
                        <span>Additional Documents</span>
                    </router-link>
                </li>
                <li
                    v-if="consist(user_cred.roles, ['qwe123','superadmin-tech', 'superadmin-product', 'admin-operation'])"
                    :class="{'uk-active': this.$route.name === 'AdditionalQuestions'}"
                >
                    <router-link :to="'/admin/additional-questions'">
                        <img v-if="this.$route.name === 'AdditionalQuestions'" :src="`${images}/additional-do-icon-yellow.svg`" alt="additional document"> 
                        <img v-else :src="`${images}/additional-do-icon.svg`" alt="additional document">
                        <span>Additional Questions</span>
                    </router-link>
                </li>
            </ul>
            <router-link
                to="/whats-new"
                :class="[this.$route.fullPath === '/whats-new' ? 'version-link-active' : 'version-link']"
            >
                <span>v{{ latest_version_data && latest_version_data.version ? latest_version_data.version : '-' }}</span>
                <img
                    v-if="this.$route.fullPath === '/whats-new'"
                    :src="`${images}/information-filled-yellow.svg`"
                    alt="Information icon"
                >
                <img
                    v-else
                    :src="`${images}/information-filled-grey.svg`"
                    alt="Information icon"
                >
            </router-link>
        </div>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { getUserLogin } from '@/utils/auth';
import { version } from "../../../../package.json";
import { mapActions, mapGetters } from 'vuex';
import { notificationDanger } from '@/utils/notification';

export default {
    data() {
        return {
            version,
            images: PREFIX_IMAGE,
            path: "M1.66669 3.33333C1.66669 3.11232 1.75448 2.90036 1.91076 2.74408C2.06704 2.5878 2.27901 " +
                "2.5 2.50002 2.5H17.5C17.721 2.5 17.933 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 " +
                "18.3334 3.33333V7.91667C17.7808 7.91667 17.2509 8.13616 16.8602 8.52686C16.4695 8.91756 16.25 " +
                "9.44747 16.25 10C16.25 10.5525 16.4695 11.0824 16.8602 11.4731C17.2509 11.8638 17.7808 12.0833 " +
                "18.3334 12.0833V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.933 17.4122 17.721 " +
                "17.5 17.5 17.5H2.50002C2.27901 17.5 2.06704 17.4122 1.91076 17.2559C1.75448 17.0996 1.66669 " +
                "16.8877 1.66669 16.6667V3.33333ZM6.73752 15.8333C6.82341 15.5892 6.98298 15.3777 7.1942 " +
                "15.2281C7.40541 15.0785 7.65786 14.9981 7.91669 14.9981C8.17552 14.9981 8.42796 15.0785 " +
                "8.63918 15.2281C8.85039 15.3777 9.00996 15.5892 9.09585 15.8333H16.6667V13.36C16.0407 " +
                "13.0498 15.5138 12.5708 15.1454 11.9771C14.7771 11.3834 14.5819 10.6987 14.5819 10C14.5819 9.30133 " +
                "14.7771 8.61656 15.1454 8.02288C15.5138 7.42921 16.0407 6.95025 16.6667 6.64V4.16667H9.09585C9.00996 " +
                "4.41083 8.85039 4.62231 8.63918 4.77191C8.42796 4.92151 8.17552 5.00186 7.91669 5.00186C7.65786 5.00186 " +
                "7.40541 4.92151 7.1942 4.77191C6.98298 4.62231 6.82341 4.41083 6.73752 4.16667H3.33335V15.8333H6.73752ZM7.91669 " +
                "9.16667C7.58517 9.16667 7.26722 9.03497 7.0328 8.80055C6.79838 8.56613 6.66669 8.24819 6.66669 7.91667C6.66669 " +
                "7.58515 6.79838 7.2672 7.0328 7.03278C7.26722 6.79836 7.58517 6.66667 7.91669 6.66667C8.24821 6.66667 8.56615 " +
                "6.79836 8.80057 7.03278C9.03499 7.2672 9.16669 7.58515 9.16669 7.91667C9.16669 8.24819 9.03499 8.56613 8.80057 " +
                "8.80055C8.56615 9.03497 8.24821 9.16667 7.91669 9.16667ZM7.91669 13.3333C7.58517 13.3333 7.26722 13.2016 7.0328 " +
                "12.9672C6.79838 12.7328 6.66669 12.4149 6.66669 12.0833C6.66669 11.7518 6.79838 11.4339 7.0328 11.1994C7.26722 " +
                "10.965 7.58517 10.8333 7.91669 10.8333C8.24821 10.8333 8.56615 10.965 8.80057 11.1994C9.03499 11.4339 " +
                "9.16669 11.7518 9.16669 12.0833C9.16669 12.4149 9.03499 12.7328 8.80057 12.9672C8.56615 13.2016 8.24821 " +
                "13.3333 7.91669 13.3333Z",
            icon_warung: "M13.3 4.49998L13.8 6.99998H2.2L2.7 4.49998H13.3ZM14.6667 0.333313H1.33333V1.99998H14.6667V0.333313ZM14.6667 " +
                "2.83331H1.33333L0.5 6.99998V8.66665H1.33333V13.6666H9.66667V8.66665H13V13.6666H14.6667V8.66665H15.5V6.99998L14.6667 " +
                "2.83331ZM3 12V8.66665H8V12H3Z"
        };
    },
    computed: {
        ...mapGetters({
            latest_version_data: 'platform/latest_version_data'
        }),
        user_cred() {
            return getUserLogin();
        }
    },
    async mounted() {
        try {
            if (navigator.onLine) { // Check internet connection
                await this.setLatestPlatformVersionData();
            }
        } catch (error) {
            notificationDanger(error);
        }
    },
    methods: {
        ...mapActions({
            getLatestVersion: 'platform/getLatestVersion'
        }),
        async setLatestPlatformVersionData() {
            try {
                if (navigator.onLine) { // Check internet connection
                    const meta = { platform_category_name: 'mabes' };
                    await this.getLatestVersion(meta);
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
    }
};
</script>

<style scoped>
.title a {
    text-transform: none;
    color: #666;
    cursor: auto;
    padding-left: 20px !important;
}
.width-large {
    width: 20.5%;
}
#toggle-animation {
    transition: width 500ms;
}
.card-sidebar {
    height: calc(100vh - 60px);
}
.sidebar {
    overflow: auto;
    background-color: #ffffff;
}
li {
    padding: 8px 0;
    border-top: 1px solid #f1f2f3;
}
li:last-child {
    border-bottom: 1px solid #f1f2f3;
}
.uk-active {
    background-color: #ffffff;
    border-left: 5px solid #f5b338;
}

.uk-active .uk-icon {
    color: #f5b338;
}
li.uk-active a {
    color: #f5b338;
}
.uk-nav-default li a.just-icon {
    padding: 0;
    text-align: center;
}
.version-link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px;
    color: #666666;
    text-decoration: none;
}

.version-link-active {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 15px;
    text-decoration: none;
    color: #f5b338;
}
</style>
